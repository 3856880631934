"use strict";

angular.module("informaApp")
    .service("PieChartHelper", ["d3", "c3", "ChartElementsHelper", function (d3, c3, ChartElementsHelper) {
        var result = {};

        function getValueOrDefault(value, defaultValue) {
            return value != null ? value : defaultValue;
        }

        result.Chart = function (canvasElement, options) {
            options = getValueOrDefault(options, {});

            var colorPattern = getValueOrDefault(options.colorPattern, ChartElementsHelper.colors);

            var width = getValueOrDefault(options.width, 200);
            var height = getValueOrDefault(options.height, 200);
            var leftTitle = getValueOrDefault(options.leftTitle, "Left Chart");
            var rightTitle = getValueOrDefault(options.rightTitle, "Right Chart");
            var leftNoDataMsg = getValueOrDefault(options.leftNoDataMsg, "No Succeeded Items Found");
            var rightNoDataMsg = getValueOrDefault(options.rightNoDataMsg, "No Failed Items Found");

            var rectWidth = 12;
            var rectOffset = 12;

            var dataLeft = options.leftData;
            var dataRight = options.rightData;

            var dataForLegend = (dataLeft.length > 0 ? dataLeft : dataRight).map(function (x) {
                return x[0];
            });

            height = height > getLegendHeight() ? height : getLegendHeight();

            var activeElementId = null;
            var disabledLegendItems = [];

            var leftContainer = d3.select(canvasElement).append("g").attr("class", "left-chart");
            var rightContainer = d3.select(canvasElement).append("g").attr("class", "right-chart");
            var legendContainer = d3.select(canvasElement).append("g").attr("class", "legend-container");

            var hasAnyColumnsInAllCharts = false;

            if (!hasAnyColumns(dataLeft)){
                dataLeft = [];
            } else {
                hasAnyColumnsInAllCharts = true;
            }

            if (!hasAnyColumns(dataRight)){
                dataRight = [];
            } else {
                hasAnyColumnsInAllCharts = true;
            }

            var leftChart = createChart(leftContainer, ".left-chart", dataLeft, leftTitle, leftNoDataMsg, getValues, colorPattern);
            var rightChart = createChart(rightContainer, ".right-chart", dataRight, rightTitle, rightNoDataMsg, getValues, colorPattern);

            bindMouseEventForChart(leftContainer, rightChart);
            bindMouseEventForChart(rightContainer, leftChart);

            if (hasAnyColumnsInAllCharts){
                var legendItem = createLegend();
            }

            updateTitles();

            function addAlertMessage(container, msg) {
                container
                    .select("svg")
                    .append("text")
                    .attr("font-size", "12px")
                    .attr("dy", "1em")
                    .attr("y", "30")
                    .text(msg)
            }

            function bindMouseEventForChart(container, chart) {
                container
                    .selectAll(".c3-chart-arc")
                    .on("mouseover", function (d) {
                        chart.focus(d.data.id);
                        setActive(d.data.id);
                    })
                    .on('mouseout', function () {
                        chart.revert();
                        setActive(null);
                    });
            }

            function createLegend() {
                var legendSvg = legendContainer.append("svg")
                    .attr("width", "500px")
                    .attr("height", (height / 2) + ((dataForLegend.length / 2 - 1) * (rectWidth + rectOffset) + rectWidth) + "px");

                var legendItem = legendSvg.selectAll("rect")
                    .data(dataForLegend.map(function (x) {return x}))
                    .enter()
                    .append("g")
                    .attr("class", "legend-item");

                legendItem
                    .append("rect")
                    .attr("width", "12px")
                    .attr("height", "12px")
                    .attr("transform", getLegendItemTransform(50, 0))
                    .attr("fill", function (d) {
                        var chart = rightChart == null ? leftChart : rightChart;
                        return chart.color(d);
                    });

                legendItem
                    .append("text")
                    .attr("font-size", "13px")
                    .attr("dy", "1em")
                    .attr("font-family", "FSLight")
                    .attr("transform", getLegendItemTransform(70, -3))
                    .text(function (d) {
                        return d;
                    });

                legendItem
                    .append("rect")
                    .attr('width', "100%")
                    .attr("height", "24px")
                    .attr("opacity", "0")
                    .attr("transform", getLegendItemTransform(0, -3))
                    .on("mouseover", function (d) {
                        if (leftChart){
                            leftChart.focus(d);
                        }
                        if (rightChart){
                            rightChart.focus(d);
                        }
                        setActive(d);
                    })
                    .on('mouseout', function (id) {
                        if (leftChart){
                            leftChart.revert();
                        }
                        if (rightChart){
                            rightChart.revert();
                        }
                        setActive(null);
                    })
                    .on('click', function (d) {
                        if (leftChart){
                            leftChart.toggle(d);
                        }
                        if (rightChart){
                            rightChart.toggle(d);
                        }
                        toggle(d);
                    });

                return legendItem;
            }

            function getLegendHeight() {
                var padding = 50;
                return (dataForLegend.length - 1) * (rectWidth + rectOffset) + rectWidth + padding;
            }

            function setActive(id) {
                activeElementId = id;
                changeColors();
            }

            function getLegendItemTransform(x, initY) {
                return function (d, i) {
                    return "translate(" + x +"," + (i * (rectWidth + rectOffset) + initY) + ")";
                };
            }

            function toggle(id){
                var index = disabledLegendItems.indexOf(id);

                if (index > -1){
                    disabledLegendItems.splice(index, 1);
                } else {
                    disabledLegendItems.push(id);
                }

                changeColors();
                updateTitles();
            }

            function changeColors() {
                legendItem.attr("text-decoration", function (d) {
                    var isDisabled = disabledLegendItems.indexOf(d);
                    return (activeElementId != null && activeElementId !== d) || isDisabled > -1 ? "line-through" : null;
                });
            }

            function createChart(element, containerSelector, data, title, emptyMsg, getValues, colorPattern) {
                var chart = c3.generate({
                    bindto: element,
                    data: {
                        columns: data,
                        type : 'pie',
                        empty: {
                            label: {
                                text: emptyMsg
                            }
                        },
                        colors: colorPattern,
                    },
                    legend: {
                        show: false,
                        position:'right',
                    },
                    tooltip:{
                        show: true,
                        position: function (data, width, height, element) {
                            var e = $(element).closest(containerSelector).find("svg");

                            var top = - e.height() + d3.mouse(e[0])[1] + 30;
                            var left = d3.mouse(e[0])[0] + 30;
                            return {top: top, left: left};
                        },
                        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                            var values = getValues(d[0].id);
                            
                            return "<div class='pie-tooltip-container'>" +
                                    "<strong style='color: " + color(d[0].id) + "'>" + d[0].id + "</strong>" +
                                    "<div class='success'>Success: " + defaultValueFormat(null, values.left.ratio) + "</div>" +
                                    "<div class='success'>Failed: " + defaultValueFormat(null, values.right.ratio)  + "</div>" +
                                "</div>";
                        }
                    },
                    size: {
                        width: width,
                        height: height
                    },
                    title: {
                        text: function () {
                            return title;
                        },
                        position: "left",
                    },
                    onrendered: function () {
                        if (options && options.onRendered){
                            options.onRendered();
                        }
                    }
                });

                return chart;
            }

            function updateTitles() {
                updateTitle(leftContainer, dataLeft, leftTitle);
                updateTitle(rightContainer, dataRight, rightTitle);
            }

            function updateTitle(container, data, title) {
                var processedData = getFilteredValueFromData(data);
                container.select('text.c3-title').node().innerHTML = title + ' (' + _.sum(processedData) + ')';
            }

            function getFilteredValueFromData(data) {
                return data
                    .filter(function (x) {
                        return disabledLegendItems.indexOf(x[0]) < 0;
                    })
                    .map(function(x) { return x[1]; })
            }

            function getValues(id) {
                function getRatio(sum, leftValue) {
                    return sum === 0 ? 0 : leftValue / sum ;
                }

                function getRightValue(chart) {
                    var values = chart.data.values(id);

                    return values == null ? 0 : values[0];
                }

                var leftSum = _.sum(getFilteredValueFromData(dataLeft));
                var rightSum = _.sum(getFilteredValueFromData(dataRight));

                var leftValue = getRightValue(leftChart);
                var rightValue = getRightValue(rightChart);

                return {
                    left: {
                        ratio: getRatio(leftSum, leftValue),
                        value: leftValue,
                    },
                    right: {
                        ratio: getRatio(rightSum, rightValue),
                        value: rightValue
                    }
                };
            }

            function hasAnyColumns(data) {
                return _.filter(data, function (x) {
                    return x[1] !== 0;
                }).length > 0;
            }
        }

        return result;
    }]);